import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {Connector, ConnectorForm} from '../../../utils/axios';
import {goAdminUrl} from '../../../utils';

const TourPicture = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [previewImage, setPreviewImage] = useState(`${process.env.PUBLIC_URL}/placeholder.png`);
  const [currentImage, setCurrentImage] = useState(null);


  useEffect(() => {
    if (formID) {
      Connector.get(`/image/tour/${formID}`)
        .then(res => {
          if(res.data.data){
            setPreviewImage(`${process.env.REACT_APP_FILE_URL}/${res.data.data}`);
            setCurrentImage(res.data.data)
          }else{
            setPreviewImage(`${process.env.PUBLIC_URL}/placeholder.png`);
            setCurrentImage(null)
          }
        })
        .catch(() => {
          setPreviewImage(`${process.env.PUBLIC_URL}/placeholder.png`);
          setCurrentImage(null)
        });
    }
  }, [formID]);

  const onChangeImage = (e) => {
    setFormData(e.target.files[0]);
    setPreviewImage(URL.createObjectURL(e.target.files[0]));
  };

  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    if (formData) {
      let _formData = new FormData();
      _formData.append('id', formID);
      _formData.append('picture', formData);
      currentImage && _formData.append('cImage', currentImage);
      ConnectorForm.post(`/image/tour`, _formData)
        .then(() => {
          setIsLoading(false);
          goAdminUrl('tours');
        })
        .catch(err => {
          setErrorMessage(err.response?.data?.data);
          setIsLoading(false);
        });
    } else {
      setErrorMessage('No Image Selected');
      setIsLoading(false);
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>Tour Picture</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <img src={previewImage} alt="banner" className="previewImage"/>
      <br/>
      <input type="file" accept="image/*" onChange={onChangeImage}/>
    </Card>
  </Container>;
};

export {TourPicture};
