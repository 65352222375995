import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {goAdminUrl, objectToArray} from '../../../utils';
import {Connector} from '../../../utils/axios';
import Input from '../../../components/input';

const initialForm = {
  'name': ''
};

const PropertiesForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const formTitle = formID ? 'Edit Properties' : 'New Properties';

  useEffect(() => {
    if (formID) {
      Connector.get(`/properties-group/${formID}`)
        .then(res => {
          setFormData(res.data.data);
        })
        .catch(() => {
          setFormData(initialForm);
        });
    }
  }, [formID]);


  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      if (formID) {
        Connector.put(`/properties-group/${formID}`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('properties');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      } else {
        Connector.post(`/properties-group`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('properties');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
    </Card>
  </Container>;
};

export {PropertiesForm};
