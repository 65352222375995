import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {goAdminUrl, objectToArray, selectListItem, storageGet} from '../../../utils';
import {Connector} from '../../../utils/axios';
import Input from '../../../components/input';
import Select from '../../../components/select';

const initialForm = {
  'name': '',
  'propertiesGroup': null
};

const YachtPropertiesForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [dataVehicle, setDataVehicle] = useState(null);
  const [dataPropsGroup, setDataPropsGroup] = useState([]);
  const formTitle = formID ? 'Edit Properties' : 'New Properties';

  useEffect(() => {
    const VehicleId = storageGet('PropertiesDetail');
    let selectPropsData = [];

    if (!VehicleId) {
      goAdminUrl('yacht');
    }

    setDataVehicle(VehicleId);
    Connector.get('/properties-group')
      .then(res => {
        selectPropsData = res.data.data.map(i => ({label: i.name, value: i.id}));
        setDataPropsGroup(selectPropsData);
      })
      .catch(() => setDataPropsGroup([]));

    if (formID) {
      Connector.get(`/properties/${formID}`)
        .then(res => setFormData(p => ({
          ...p,
          ...res.data.data,
          propertiesGroup: selectListItem(selectPropsData, res?.data?.data?.propertiesGroupId)
        })))
        .catch(() => setFormData(initialForm));
    }
  }, [formID]);

  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      formData.propertiesGroupId = formData.propertiesGroup.value;
      formData.vehicleId = dataVehicle;
      if (formID) {
        Connector.put(`/properties/${formID}`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl(`yacht-properties/${dataVehicle}`);
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      } else {
        Connector.post(`/properties`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl(`yacht-properties/${dataVehicle}`);
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Select
        label="Group Name"
        data={dataPropsGroup}
        value={formData.propertiesGroup}
        onChange={e => setFormData({...formData, propertiesGroup: e})}
      />
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
    </Card>
  </Container>;
};

export {YachtPropertiesForm};
