import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {goAdminUrl, objectToArray} from '../../../utils';
import {Connector} from '../../../utils/axios';
import Input from '../../../components/input';
import Select from '../../../components/select';

const initialForm = {
  'name': '',
  'description': '',
  'days': '',
  'country': null,
  'location': null
};

const ToursForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [dataCountry, setDataCountry] = useState([]);
  const [dataLocations, setDataLocations] = useState([]);
  const formTitle = formID ? 'Edit Tours' : 'New Tours';

  useEffect(() => {
    Connector.get('/locations')
      .then(res => {
        //setFormData(p => ({...p, location: null}));
        setDataCountry(res.data.data.map(i => ({
          label: i.name,
          value: i.id
        })));
      })
      .catch(() => {
        setDataCountry([]);
      });
    if (formID) {
      Connector.get(`/tours/${formID}`)
        .then(res => {
          setFormData(res.data.data);
          Connector.get(`admin/locations/${res.data.data.locationId}`)
            .then(resLoc => setFormData(p => ({
              ...p,
              country: {label: resLoc.data.data.parent.name, value: resLoc.data.data.parent.id},
              location: {label: resLoc.data.data.name, value: resLoc.data.data.id}
            }))).catch(() => null);
        })
        .catch(() => {
          setFormData(initialForm);
        });
    }
  }, [formID]);

  useEffect(() => {
    if (formData.country) {
      Connector.get(`locations/${formData.country.value}`)
        .then(res => {
          setDataLocations(res.data.data.map(i => ({
            label: i.name,
            value: i.id
          })));
        })
        .catch(() => {
          setDataLocations([]);
        });
    }
  }, [formData.country]);

  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      formData.locationId = formData.location.value;
      if (formID) {
        Connector.put(`/tours/${formID}`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('tours');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      } else {
        Connector.post(`/tours`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('tours');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
      <Input
        label="Description"
        value={formData.description}
        onChange={e => setFormData({...formData, description: e.target.value})}
      />
      <Input
        label="Days"
        value={formData.days}
        onChange={e => setFormData({...formData, days: e.target.value})}
      />
      <Select
        label="Country"
        data={dataCountry}
        value={formData.country}
        onChange={e => setFormData({...formData, country: e})}
      />
      <Select
        label="Location"
        data={dataLocations}
        value={formData.location}
        onChange={e => setFormData({...formData, location: e})}
      />
    </Card>
  </Container>;
};

export {ToursForm};
