const strings = {
  PATH: {
    ADMIN: '/admin',
    SECURE: '/secure'
  },
  DateTimeFormat: {
    YYYYMMDD_T_HHmmss: 'YYYY-MM-DD[T]HH:mm:ss',
    DDMMYYYY_HHmmss: 'DD.MM.YYYY HH:mm:ss',
    DDMMYYYY_HHmm: 'DD.MM.YYYY HH:mm',
    HHmmss: 'HH:mm:ss',
    HHmm: 'HH:mm'
  }
};
const vehicleTypeSelectList = [
  {label: 'Motoryacht', value: 'MOTORYACHT'},
  {label: 'Trawler', value: 'TRAWLER'},
  {label: 'Catamaran', value: 'CATAMARAN'},
  {label: 'Bare Boat', value: 'BAREBOAT'},
  {label: 'Gulet', value: 'GULET'}
];


export {strings, vehicleTypeSelectList};
