import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {goAdminUrl, objectToArray, selectListItem, vehicleTypeSelectList} from '../../../utils';
import {Connector} from '../../../utils/axios';
import Input from '../../../components/input';
import Select from '../../../components/select';
import TextArea from '../../../components/textarea';

const initialForm = {
  'name': '',
  'description': '',
  'length': '',
  'yearBuild': '',
  'yearRenovated': '',
  'cabinCount': '',
  'maxSize': '',
  'location': null,
  '_location': null,
  '_country': null,
  '_type': null,
  'type': null,
  'speed': '',
  'price': {
    'daily': '',
    'weekly': '',
    'monthly': ''
  }
};

const YachtForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [dataCountry, setDataCountry] = useState([]);
  const [dataLocations, setDataLocations] = useState([]);
  const formTitle = formID ? 'Edit Yacht' : 'New Yacht';

  useEffect(() => {
    Connector.get('/locations')
      .then(res => {
        //setFormData(p => ({...p, location: null}));
        setDataCountry(res.data.data.map(i => ({
          label: i.name,
          value: i.id
        })));
      })
      .catch(() => {
        setDataCountry([]);
      });
    if (formID) {
      Connector.get(`/vehicle/${formID}/admin`)
        .then(res => {
          setFormData(res.data.data);
          Connector.get(`admin/locations/${res.data.data.location}`)
            .then(resLoc => setFormData(p => ({
              ...p,
              _country: {label: resLoc.data.data.parent.name, value: resLoc.data.data.parent.id},
              _location: {label: resLoc.data.data.name, value: resLoc.data.data.id},
              _type: selectListItem(vehicleTypeSelectList,res.data.data.type)
            }))).catch(() => null);
        })
        .catch(() => {
          setFormData(initialForm);
        });
    }
  }, [formID]);

  useEffect(() => {
    if (formData._country) {
      Connector.get(`locations/${formData._country.value}`)
        .then(res => {
          setDataLocations(res.data.data.map(i => ({
            label: i.name,
            value: i.id
          })));
        })
        .catch(() => {
          setDataLocations([]);
        });
    }
  }, [formData._country]);

  const onSubmitForm = () => {
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      formData.location = formData._location.value;
      formData.type = formData._type.value;
      if (formID) {
        Connector.put(`/vehicle/${formID}`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('yacht');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      } else {
        Connector.post(`/vehicle`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl('yacht');
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
      <TextArea
        label="Description"
        value={formData.description}
        onChange={e => setFormData({...formData, description: e.target.value})}
      />
      <Row>
        <Col md={{span: 6}}>
          <Input
            label="Length"
            value={formData.length}
            onChange={e => setFormData({...formData, length: e.target.value})}
            formProps={{placeholder: '88.5m (290.4ft)'}}
          >
            <small style={{fontStyle: 'italic'}}>Sample: 88.5m (290.4ft)</small>
          </Input>
          <Input
            label="Build Year"
            value={formData.yearBuild}
            onChange={e => setFormData({...formData, yearBuild: e.target.value})}
            formProps={{type: 'number'}}
          />
          <Input
            label="Renovated Year"
            value={formData.yearRenovated}
            onChange={e => setFormData({...formData, yearRenovated: e.target.value})}
            formProps={{type: 'number'}}
          />
          <Input
            label="Cabin Count"
            value={formData.cabinCount}
            onChange={e => setFormData({...formData, cabinCount: e.target.value})}
            formProps={{type: 'number'}}
          />
          <Input
            label="Max Size"
            value={formData.maxSize}
            onChange={e => setFormData({...formData, maxSize: e.target.value})}
            formProps={{type: 'number'}}
          />
          <Input
            label="Speed"
            value={formData.speed}
            onChange={e => setFormData({...formData, speed: e.target.value})}
            formProps={{
              type: 'number'
            }}
          />
        </Col>
        <Col md={{span: 6}}>
          <Select
            label="Type"
            data={vehicleTypeSelectList}
            value={formData._type}
            onChange={e => setFormData({...formData, _type: e})}
          />
          <Select
            label="Country"
            data={dataCountry}
            value={formData._country}
            onChange={e => setFormData({...formData, _country: e})}
          />
          <Select
            label="Location"
            data={dataLocations}
            value={formData._location}
            onChange={e => setFormData({...formData, _location: e})}
          />
          <Input
            label="Price - Daily"
            value={formData.price.daily}
            onChange={e => setFormData({...formData, price: {...formData.price, daily: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Price - Weekly"
            value={formData.price.weekly}
            onChange={e => setFormData({...formData, price: {...formData.price, weekly: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
          <Input
            label="Price - Monthly"
            value={formData.price.monthly}
            onChange={e => setFormData({...formData, price: {...formData.price, monthly: e.target.value}})}
            formProps={{
              type: 'number'
            }}
          />
        </Col>
      </Row>
    </Card>
  </Container>;
};

export {YachtForm};
