import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Modal, Row} from 'react-bootstrap';
import {Connector, ConnectorForm} from 'utils/axios';
import {goAdminUrl} from 'utils';
import FileUpload from 'components/fileUpload';

const FileView = (props) => {
  return <div className="mb-3">
    <img src={`${process.env.REACT_APP_FILE_URL}/${props.name}`} alt="pictures" className="previewImageSingle"/>
    <div className="previewImageSingleRemove" onClick={props.onClick}>Remove</div>
  </div>;
};

const Photo = () => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [formData, setFormData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    Connector.get(`/photo`)
      .then(res => {
        //View: `${process.env.REACT_APP_FILE_URL}/filename.jpg`
        setUploadedFiles(res.data.data);
      })
      .catch(() => {
        setUploadedFiles([]);
      });

  }, []);

  const onSubmitUpload = () => {
    setErrorMessage(null);
    setIsLoading(true);
    if (formData && formData.length > 0) {
      let _formData = new FormData();
      formData.forEach(item => _formData.append('pictures', item));
      ConnectorForm.put(`/photo`, _formData)
        .then(() => {
          setIsLoading(false);
          goAdminUrl(`photo`);
        })
        .catch(err => {
          setErrorMessage(err.response?.data?.data);
          setIsLoading(false);
        });
    } else {
      setErrorMessage('No Image Selected');
      setIsLoading(false);
    }
  };
  const onSubmitRemove = (id) => {
    Connector.delete(`/photo/${id}`)
      .then(() => {
        Connector.get(`/photo`)
          .then(res => {
            //View: `${process.env.REACT_APP_FILE_URL}/filename.jpg`
            setUploadedFiles(res.data.data);
          })
          .catch(() => {
            setUploadedFiles([]);
          });
      })
      .catch(err => {
        setErrorMessage(err.response?.data?.data);
      });
  };

  return <Container>
    <div className="pageBrach">
      <h3>Photos</h3>
    </div>

    <Card body className="mb-2">
      <div className="formErrorMessage">{errorMessage}</div>
      <FileUpload onChange={val => setFormData(val)}/>
      <div className="text-center mt-3">
        <Button onClick={onSubmitUpload} disabled={isLoading}>Upload New Files</Button>
      </div>
    </Card>

    <Card body>
      <Row>
        {uploadedFiles.map(item => <Col md={{span: 6}}>
          <FileView name={item.name} onClick={() => {
            setModalData(item.id);
            setShowModal(true);
          }}/>
        </Col>)}
      </Row>
    </Card>
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to remove Image?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {
          setShowModal(false);
          onSubmitRemove(modalData);
          setModalData(null);
        }}>Yes, Remove It</Button>
      </Modal.Footer>
    </Modal>
  </Container>;
};

export {Photo};
