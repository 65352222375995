import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Modal, Table} from 'react-bootstrap';
import {goAdminUrl, storageSet} from '../../../utils';
import {Icon} from '../../../components/Icon';
import {Connector} from '../../../utils/axios';
import {DatatableWrapper, Pagination, TableBody, TableHeader} from 'react-bs-datatable';

const TourDetailList = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [data, setData] = useState([]);
  const [dataTour, setDataTour] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    storageSet('TourDetail', formID);
    Connector.get(`/tour-data/admin/${formID}`)
      .then(res => setData(res.data.data))
      .catch(e => console.log(e.toString()));
    Connector.get(`/tours/${formID}`)
      .then(res => setDataTour(res.data.data))
      .catch(e => console.log(e.toString()));
  }, [formID]);

  const onSubmitRemove = async (data) => {
    await Connector.delete(`/tour-data/${data}`);
    Connector.get(`/tour-data/admin/${formID}`)
      .then(res => setData(res.data.data))
      .catch(e => console.log(e.toString()));
  };

  const tableColumn = [
    {
      prop: 'button',
      title: '#',
      thProps: {
        width: '20px'
      },
      cell: () => <># </>
    },
    {
      prop: 'day',
      title: 'Day'
    },
    {
      prop: 'locationName',
      title: 'Location'
    },
    {
      prop: 'button',
      title: <Icon item="bolt"/>,
      thProps: {
        width: '120px'
      },
      cell: (row) => <>
        <Icon item="picture-o pointer postIcon" onClick={() => goAdminUrl(`tour-detail/picture/${row.id}`)}/>
        <Icon item="edit pointer postIcon" onClick={() => goAdminUrl(`tour-detail/edit/${row.id}`)}/>
        <Icon item="trash pointer postIcon" onClick={() => {
          setModalData(row.id);
          setShowModal(true);
        }}/>
      </>
    }
  ];

  return <Container>
    <div className="pageBrach">
      <h3>Tour Detail of {dataTour.name}</h3>
      <Button onClick={() => goAdminUrl(`tour-detail/new`)}>New Detail</Button>
    </div>
    <Card body>
      <DatatableWrapper
        body={data}
        headers={tableColumn}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10
          }
        }}>
        <Table>
          <TableHeader/>
          <TableBody/>
        </Table>
        <div className="text-end">
          <Pagination/>
        </div>
      </DatatableWrapper>
    </Card>
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title>Remove Tour Detail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Do you want to remove tour detail?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={() => {
          setShowModal(false);
          onSubmitRemove(modalData);
          setModalData(null);
        }}>Yes, Remove It</Button>
      </Modal.Footer>
    </Modal>
  </Container>;
};

export {TourDetailList};
