import React, {useEffect, useState} from 'react';
import {Button, Card, Container, Table} from 'react-bootstrap';
import {DatatableWrapper, Pagination, TableBody, TableHeader} from 'react-bs-datatable';
import {goAdminUrl} from '../../../utils';
import {Icon} from '../../../components/Icon';
import {Connector} from '../../../utils/axios';

const LocationList = () => {
  const [data, setData] = useState([]);
  const [showDetail, setShowDetail] = useState(null);

  useEffect(() => {
    Connector.get('/admin/locations')
      .then(res => {
        setData(res.data.data);
      })
      .catch(e => {
        console.log(e.toString());
      });
  }, []);

  const tableColumnDetail = [
    {
      prop: 'button',
      title: <Icon item="bolt"/>,
      thProps: {
        width: '75px'
      },
      cell: (row) => <>
        <Icon item="picture-o pointer postIcon" onClick={() => goAdminUrl(`location/picture/${row.id}`)}/>
        <Icon item="edit pointer postIcon" onClick={() => goAdminUrl(`location/${row.id}`)}/>
      </>
    },
    {
      prop: 'name',
      title: 'Name'
    }
  ];

  const tableColumn = [
    {
      prop: 'button',
      title: '#',
      thProps: {
        width: '20px'
      },
      cell: () => <># </>
    },
    {
      prop: 'name',
      title: 'Name'
    },
    {
      prop: 'button',
      title: 'Detail',
      cell: (row) => <div className="dashBox"
                          style={{display: showDetail === row.id ? 'block' : 'none', marginTop: '10px'}}>
        <DatatableWrapper
          body={row.detail}
          headers={tableColumnDetail}
          paginationOptionsProps={{
            initialState: {
              rowsPerPage: 4
            }
          }}>
          <Table>
            <TableBody/>
          </Table>
          <div className="text-end">
            <Pagination/>
          </div>
        </DatatableWrapper>
      </div>
    },
    {
      prop: 'button',
      title: <Icon item="bolt"/>,
      thProps: {
        width: '120px'
      },
      cell: (row) => <>
        <Icon item="picture-o pointer postIcon" onClick={() => goAdminUrl(`location/picture/${row.id}`)}/>
        <Icon item="search pointer postIcon" onClick={() => setShowDetail(row.id)}/>
        <Icon item="edit pointer postIcon" onClick={() => goAdminUrl(`location/${row.id}`)}/>
      </>
    }
  ];

  return <Container>
    <div className="pageBrach">
      <h3>Locations</h3>
      <Button onClick={() => goAdminUrl('location/new')}>New</Button>
    </div>
    <Card body>
      <DatatableWrapper
        body={data}
        headers={tableColumn}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10
          }
        }}>
        <Table>
          <TableHeader/>
          <TableBody/>
        </Table>
        <div className="text-end">
          <Pagination/>
        </div>
      </DatatableWrapper>
    </Card>
  </Container>;
};

export {LocationList};
