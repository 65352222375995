import React, {useEffect, useState} from 'react';
import {Button, Card, Container} from 'react-bootstrap';
import {goAdminUrl, objectToArray} from 'utils';
import {Connector} from 'utils/axios';
import Input from 'components/input';
import Select from 'components/select';

const initialForm = {
  'name': '',
  'parentId': null,
  'country': null
};

const LocationForm = (props) => {
  const [formID] = useState(props.match?.params?.id ?? null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialForm);
  const [dataCountry, setDataCountry] = useState([]);
  const formTitle = formID ? 'Edit Location' : 'New Location';

  useEffect(() => {
    Connector.get('/locations')
      .then(res => {
        setDataCountry(res.data.data.map(i => ({
          label: i.name,
          value: i.id
        })));
      })
      .catch(() => {
        setDataCountry([]);
      });
    if (formID) {
      Connector.get(`/locations/${formID}/admin`)
        .then(res => {
          setFormData(res.data.data);
        })
        .catch(() => {
          setFormData(initialForm);
        });
    }
  }, [formID]);

  const onSubmitForm = () => {
    formData.parentId = formData?.country?.value ?? null;
    setErrorMessage(null);
    setIsLoading(true);
    let error = false;
    objectToArray(formData).forEach(item => {
      if (item.val === '')
        error = true;
    });
    if (error) {
      setErrorMessage('Please Fill Fields');
      setIsLoading(false);
    } else {
      if (formID) {
        Connector.put(`/locations/${formID}`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl(`location`);
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      } else {
        Connector.post(`/locations`, formData)
          .then(() => {
            setIsLoading(false);
            goAdminUrl(`location`);
          })
          .catch(err => {
            setErrorMessage(err.response?.data?.data);
            setIsLoading(false);
          });
      }
    }
  };

  return <Container>
    <div className="pageBrach">
      <h3>{formTitle}</h3>
      <Button onClick={onSubmitForm} disabled={isLoading}>Save</Button>
    </div>
    <div className="formErrorMessage">{errorMessage}</div>
    <Card body>
      <Input
        label="Name"
        value={formData.name}
        onChange={e => setFormData({...formData, name: e.target.value})}
      />
      {!formID && <Select
        label="Country"
        data={dataCountry}
        value={formData.country}
        onChange={e => setFormData({...formData, country: e})}
      />}
    </Card>
  </Container>;
};

export {LocationForm};
