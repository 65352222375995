import React from 'react';
import {createRoot} from 'react-dom/client';
import {Router, Switch} from 'react-router-dom';
import {history, strings} from 'utils';
import './style.css';

import {SecureRouter} from './context/secure';
import {AdminRouter} from './context/admin';
import {Login} from './view/secure/login';
import {Dashboard} from './view/admin/dashboard';
import {Routes} from './view/admin/routes';
import {RoutesForm} from './view/admin/routesForm';
import {ToursList, ToursForm, TourPicture} from './view/admin/tour';
import {LocationForm, LocationList, LocationPicture} from './view/admin/location';
import {YachtForm, YachtList, YachtGallery, YachtBanner} from './view/admin/yacth';
import {TourDetailForm, TourDetailList, TourDetailPicture} from './view/admin/tour-detail';
import {Photo} from './view/admin/photo';
import {PropertiesForm, PropertiesList} from './view/admin/properties';
import {YachtPropertiesForm, YachtPropertiesList} from './view/admin/yacht-properties';

const App = () => {
  let adminPrefix = strings.PATH.ADMIN;

  return <>
    <Router history={history}>
      <Switch>
        <SecureRouter exact path={'/'} component={Login}/>
        <AdminRouter exact path={adminPrefix + '/dashboard'} component={Dashboard}/>

        <AdminRouter exact path={adminPrefix + '/yacht'} component={YachtList}/>
        <AdminRouter exact path={adminPrefix + '/yacht/banner/:id'} component={YachtBanner}/>
        <AdminRouter exact path={adminPrefix + '/yacht/gallery/:id'} component={YachtGallery}/>
        <AdminRouter exact path={adminPrefix + '/yacht/new'} component={YachtForm}/>
        <AdminRouter exact path={adminPrefix + '/yacht/:id'} component={YachtForm}/>

        <AdminRouter exact path={adminPrefix + '/routes'} component={Routes}/>
        <AdminRouter exact path={adminPrefix + '/routes/new'} component={RoutesForm}/>
        <AdminRouter exact path={adminPrefix + '/routes/:id'} component={RoutesForm}/>

        <AdminRouter exact path={adminPrefix + '/location'} component={LocationList}/>
        <AdminRouter exact path={adminPrefix + '/location/new'} component={LocationForm}/>
        <AdminRouter exact path={adminPrefix + '/location/picture/:id'} component={LocationPicture}/>
        <AdminRouter exact path={adminPrefix + '/location/:id'} component={LocationForm}/>

        <AdminRouter exact path={adminPrefix + '/tours'} component={ToursList}/>
        <AdminRouter exact path={adminPrefix + '/tours/new'} component={ToursForm}/>
        <AdminRouter exact path={adminPrefix + '/tours/picture/:id'} component={TourPicture}/>
        <AdminRouter exact path={adminPrefix + '/tours/:id'} component={ToursForm}/>

        <AdminRouter exact path={adminPrefix + '/tour-detail/new'} component={TourDetailForm}/>
        <AdminRouter exact path={adminPrefix + '/tour-detail/edit/:id'} component={TourDetailForm}/>
        <AdminRouter exact path={adminPrefix + '/tour-detail/picture/:id'} component={TourDetailPicture}/>
        <AdminRouter exact path={adminPrefix + '/tour-detail/:id'} component={TourDetailList}/>

        <AdminRouter exact path={adminPrefix + '/yacht-properties/new'} component={YachtPropertiesForm}/>
        <AdminRouter exact path={adminPrefix + '/yacht-properties/edit/:id'} component={YachtPropertiesForm}/>
        <AdminRouter exact path={adminPrefix + '/yacht-properties/:id'} component={YachtPropertiesList}/>

        <AdminRouter exact path={adminPrefix + '/properties'} component={PropertiesList}/>
        <AdminRouter exact path={adminPrefix + '/properties/new'} component={PropertiesForm}/>
        <AdminRouter exact path={adminPrefix + '/properties/:id'} component={PropertiesForm}/>

        <AdminRouter exact path={adminPrefix + '/photo'} component={Photo}/>

      </Switch>
    </Router>
  </>;
};

const root = createRoot(document.getElementById('root'));

root.render(<App/>);

