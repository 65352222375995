import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import {idSlugify} from '../utils';

const TextArea = props => {
  return <Form.Group className="mb-3" controlId={idSlugify(props.label)}>
    <Form.Label>{props.label}</Form.Label>
    <Form.Control
      placeholder={props.label}
      value={props.value}
      onChange={e => props.onChange(e)}
      as="textarea"
      rows={3}
      {...props.formProps ?? {}}
    />
    {props.children}
  </Form.Group>;
};

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  formProps: PropTypes.object
};
export default TextArea;
